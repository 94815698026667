import React from "react";
import { Box, Text, Modal, ModalOverlay, ModalContent,  ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";

const ProfileOrderDetailsModal = ({ order, isOpen, onClose }) => {
  const { name, phone, order_type, address_comment, order_comment, payments, items } = order;
console.log(items)
  const totalPayment = payments.reduce((acc, payment) => acc + payment.sum, 0);
  const products = items.map((item, index) => <Text key={index}>Item: {item.name}, Amount: {item.amount}</Text>);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
      
        <ModalCloseButton />
        <ModalBody>
          <Box p={2}>
            <Text>Name: {name}</Text>
            <Text>Phone: {phone}</Text>
            <Text>Order Type: {order_type}</Text>
            <Text>Address Comment: {address_comment}</Text>
            <Text>Order Comment: {order_comment}</Text>
            <Text>Total Payment: {totalPayment}</Text>
            {products}
          </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfileOrderDetailsModal;