import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CartContext, useCart } from "./CartContext";
import Categories from "./Categories";
import { Groups } from "./Groups";
import { CustomerInfo } from "./CustomerInfo";
import RegistrationForm from "./RegistrationForm";

import Navigation from "./Navigation";
import CheckoutNav from "./CheckoutNav";
import Checkout from "./Checkout";
import { ChakraProvider, Container } from "@chakra-ui/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Order from "./Order";
import Profile from "./Profile";
import Helmet from "react-helmet";
import OrderTable from "./OrderTable";

import { extendTheme } from "@chakra-ui/react";
import '@fontsource-variable/roboto-flex/full.css';

export const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
     
      },
      // styles for the `a`
   
    },
  },
  fonts: {
    heading:  "'Roboto Flex Variable', sans-serif",
    body: "'Roboto Flex Variable', sans-serif",
  },
  colors: {
    brand: {
    
    },
  },

});

const ConditionalNavigation = () => {
  const location = useLocation();

  // Define routes where you don't want to show navigation
  const noNavRoutes = [ "/checkout", "/customer", '/order-table', '/registration'];
  const checkoutRoutes = [ "/order"];

  // If the current route is in noNavRoutes, don't render Navigation
  if (noNavRoutes.includes(location.pathname)) {
    return <Container h={['60px',  '60px', '80px',  '80px']}/>;
  }

  if (checkoutRoutes.includes(location.pathname)) {
    return <CheckoutNav />;
  }

  return <Navigation />;
};

const App = () => {
  const cart = useCart();
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            bottom: "80px",
            position: "top",
            duration: 2000,
            isClosable: true,
          },
        }}
      >
        <CartContext.Provider value={cart}>
          <Router>
            <Helmet
              title= {process.env.REACT_APP_SEO_TITLE}
              meta={[
                {
                  name: "description",
                  content:process.env.REACT_APP_SEO_DESCRIPTION,
                },
              ]}
              link={[
                {
                  rel: "apple-touch-icon",
                  href: "http://mysite.com/img/apple-touch-icon-57x57.png",
                },
                {
                  rel: "apple-touch-icon",
                  sizes: "72x72",
                  href: "http://mysite.com/img/apple-touch-icon-72x72.png",
                },
              ]}
            />

            <ConditionalNavigation />

            <Container maxW="1600px" px={[4, 4, 6, 6]}  pb={[2, 4, 4, 4]} mb={[20]}>
              <Routes>
                <Route path="/" element={<Categories />} />
                <Route path="/groups/:uuid" element={<Groups />} />
                <Route path="/customer" element={<CustomerInfo />} />
                <Route path="/order/:uuid" element={<Order />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/order-table" element={<OrderTable />} />
                <Route path="/registration" element={<RegistrationForm />} />
              </Routes>
            </Container>
          </Router>
        </CartContext.Provider>
      </ChakraProvider>
    </I18nextProvider>
  );
};

export default App;
