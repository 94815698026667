import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  FormControl,
  FormErrorMessage,
  Input,
  Flex,
  Text,
  Switch,
  Box,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Badge,
  Spacer,
} from "@chakra-ui/react";
import PhoneNumberInput from "./PhoneNumberInput";

// Components for the inputs
const NameInput = ({ defaultValue, placeholder, onChange, isInvalid }) => (
  <FormControl isInvalid={isInvalid}  >
    <Input
      type="text"
      name="name"
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
    />
    <FormErrorMessage>Укажите Ваше имя.</FormErrorMessage>
  </FormControl>
  
);

const PhoneInput = ({ defaultValue, onChange, isDisabled = false }) => (
  <FormControl isRequired="true">
    <Input
      name="phone"
      defaultValue={defaultValue}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  </FormControl>
);

// Component for the slider
const BonusSlider = ({ displayBalance, sliderValue, setSliderValue }) => (
  <Box pt={2} pb={2} px={4}>
    <Slider
      aria-label="slider-bonuses"
      defaultValue={0}
      min={0}
      max={displayBalance}
      onChange={setSliderValue}
    >
      <SliderMark
        value={sliderValue}
        textAlign="center"
        color="white"
        bg="blue.500"
        mt="-10"
        ml="-5"
        w="12"
      >
        {sliderValue} {process.env.REACT_APP_CURRENCY_SYMBOL}
      </SliderMark>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={6} />
    </Slider>
  </Box>
);

const CheckoutProfile = ({
  profileData,
  handlePhoneChange,
  setName,
  bonusesBool,
  setBonusesBool,
  displayBalance,
  sliderValue,
  setSliderValue,
}) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [isNameInvalid, setIsNameInvalid] = useState(false);

  return (
    <>
      {profileData ? (
        <>
          <Stack direction={["column", "column", "row"]} spacing={4} my={4} alignItems={'flex-start'}>
            <NameInput
              placeholder={profileData.name}
              defaultValue={profileData.name}
              onChange={(e) => setName(e.target.value)}
            />
            <PhoneInput
              placeholder={profileData.phone}
              defaultValue={profileData.phone}
              onChange={handlePhoneChange}
              isDisabled
            />
          </Stack>

          <Flex align="center" justify={"space-between"}>
            <Text>{t("spendLoyality")}</Text>
            <Badge ml={2}>{displayBalance}</Badge>
            <Spacer />
            <Switch
              ml={2}
              name="bonusesBool"
              value={bonusesBool}
              onChange={(e) => {
                const checked = e.target.checked;
                setBonusesBool(checked);
              }}
            />
          </Flex>

          {bonusesBool && (
            <BonusSlider
              displayBalance={displayBalance}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          )}
        </>
      ) : (
        <Stack
          direction={["column", "column", "row"]}
          align="center"
          spacing={4}
          my={4}
          w="full"
          alignItems={'flex-start'}
        >
          <NameInput 
          isInvalid={isNameInvalid}
            placeholder={t("name")}
            onChange={(e) => {
              setName(e.target.value);
              setIsNameInvalid(!e.target.value); // if name is empty, setIsNameInvalid will be set to true
            }}
          />
          <PhoneNumberInput
            name="phone"
            placeholder={t("phone")}
            value={phone}
            onChange={handlePhoneChange}
          />
        </Stack>
      )}
    </>
  );
};

export default CheckoutProfile;
