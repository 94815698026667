import { useEffect, useState } from "react";

const useOrderHistory = (TENANT_ID, user_id) => {
  const [orderHistory, setOrderHistory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user_id) {
          const url = process.env.REACT_APP_MAIN_API_URL + `api:aYQXf2CE/orders?tenants_id=${TENANT_ID}&user_id=${user_id}`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            setOrderHistory(data);
          } else {
            console.error("Error fetching order History:", response.statusText);
          }
        }
      } catch (error) {
        console.error("Error fetching order History:", error);
      }
    };

    fetchData();
  }, [TENANT_ID, user_id]);

  return orderHistory;
};

export default useOrderHistory;
