import React from "react";
import { Box, Button, Heading, Spinner, Text } from "@chakra-ui/react";
import ProfileOrdersTable from "./ProfileOrdersTable";
import useProfileData from "./hooks/useProfileData";
import useOrderHistory from "./hooks/useOrderHistory";
import { useTranslation } from "react-i18next";
const Profile = () => {
  const TENANT_ID = process.env.REACT_APP_TENANT_ID;
  const secret_key = localStorage.getItem("secret_key");
  const profileData = useProfileData(TENANT_ID, secret_key);
  const user_id = profileData?.id;
  const orderHistory = useOrderHistory(TENANT_ID, user_id);
  const { t } = useTranslation();
  if (!profileData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  const totalBalance = profileData.walletBalances.reduce(
    (sum, balance) => sum + balance.balance,
    0
  );

  const formatDateString = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handleLogout = () => {
    // Clear secret key from local storage
    localStorage.removeItem("secret_key");
    // Redirect to main page
    window.location.href = "/";
  };

  return (
    <Box>
      <Heading as="h2" size="lg" mb={4}>
        {t("hello")}!
      </Heading>
      <Box>
      {profileData.name && (
          <Text>
            <strong>{t("name")}:</strong>{" "}
            {profileData.name}
          </Text>
        )}
        {profileData.birthday && (
          <Text>
            <strong>{t("birthday")}:</strong>{" "}
            {formatDateString(profileData.birthday)}
          </Text>
        )}
        {profileData.phone && (
          <Text>
            <strong>{t("phone")}:</strong> {profileData.phone}
          </Text>
        )}
        {profileData.email && (
          <Text>
            <strong>Email:</strong> {profileData.email}
          </Text>
        )}
      
          <Text>
            <strong>{t("cart.bonuses")}:</strong> {totalBalance && totalBalance}
          </Text>
     
   
             <Heading as="h2" size="lg" my={4}>
        {t("profile.orderhistory")}
      </Heading>
      {orderHistory && (
      <ProfileOrdersTable data={orderHistory} />
      )}
        <Button onClick={handleLogout} variant={"link"} mt={4}>
          {t("button.logout")}
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;
