import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Grid, Text, ModalFooter, Heading } from '@chakra-ui/react';
import { startOfDay, endOfDay, format, addMinutes, isAfter, addDays, parse, isToday, isTomorrow } from "date-fns";

function TimeModal({ startTime, endTime, selectedDateTime, setSelectedDateTime }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const toggleModal = () => setIsOpen(!isOpen);

    const generateTimes = (start, end, date) => {
        let times = [];
        let current = new Date(date).setHours(...start.split(':'));
        let finish = new Date(date).setHours(...end.split(':'));
    
        while (current < finish) {
            const nextTime = addMinutes(current, 60);
            if(isToday(date) && current <= new Date().setHours(new Date().getHours() + 1)) {
                current = nextTime;
                continue;
            }
            times.push(format(current, 'HH:mm') + ' - ' + format(nextTime, 'HH:mm'));
            current = nextTime;
        }
    
        return times;
    }

    const handleDateSelection = (date) => {
        setSelectedDate(date);

        // If a time has been selected, update the selectedDateTime with the new date.
        if (selectedDateTime) {
            const time = parse(selectedDateTime, "yyyy-MM-dd'T'HH:mm", new Date());
            date.setHours(time.getHours(), time.getMinutes());
            setSelectedDateTime(format(date, "yyyy-MM-dd'T'HH:mm"));
        }
    }

    const handleTimeSelection = (time) => {
        const [start] = time.split(' - ');
        const selectedTime = parse(start, 'HH:mm', new Date());
        const dateTime = new Date(selectedDate);
        dateTime.setHours(selectedTime.getHours(), selectedTime.getMinutes());
        setSelectedDateTime(format(dateTime, "yyyy-MM-dd'T'HH:mm"));
        setIsOpen(false);
    }

    const currentDateTime = new Date();
    const endDate = new Date().setHours(...endTime.split(':'));
    const startDate = isAfter(currentDateTime, endDate) ? addDays(new Date(), 1) : new Date();

    const dateButtons = ['Сегодня', 'Завтра'].map((label, i) => {
        const date = addDays(startDate, i);
        const isSelected = selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth() && selectedDate.getFullYear() === date.getFullYear();
        return (
            <Button
                key={i}
                onClick={() => handleDateSelection(date)}
                colorScheme={isSelected ? "blue" : "gray"}
            >
                {label} 
            </Button>
        );
    });
    
    const formatSelectedDateTime = () => {
        const dateTime = parse(selectedDateTime, "yyyy-MM-dd'T'HH:mm", new Date());
        let formattedDate;

        if (isToday(dateTime)) {
            formattedDate = 'Сегодня';
        } else if (isTomorrow(dateTime)) {
            formattedDate = 'Завтра';
        } else {
            formattedDate = format(dateTime, 'dd/MM/yyyy');
        }

        const formattedTime = format(dateTime, 'HH:mm');
        const endIntervalTime = format(addMinutes(dateTime, 60), 'HH:mm');

        return `${formattedDate} ${formattedTime}-${endIntervalTime}`;
    };
    
    return (
        <>
            <Button onClick={toggleModal} isActive={selectedDateTime !== null}>  {selectedDateTime ? formatSelectedDateTime() : 'Выбрать дату и время'}</Button>

            <Modal isOpen={isOpen} onClose={toggleModal} size={['full', 'full', 'xl']}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Heading size='md' mb={4}> Дата </Heading>  
                    <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
                            {dateButtons}
                        </Grid>
                        <Heading size='md' mb={4}> Время </Heading>
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        
                            {
                                
                            generateTimes(startTime, endTime, selectedDate).map(time => (
                                <Button key={time} onClick={() => handleTimeSelection(time)}>
                                    {time}
                                </Button>
                            ))
                            }
                        </Grid>
                    </ModalBody>
                    <ModalFooter> {selectedDateTime && <Text mt={4}>{formatSelectedDateTime()}</Text>}</ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default TimeModal;
