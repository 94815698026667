import React from "react";
import {
  FormControl,
  Input,
} from "@chakra-ui/react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";

const PhoneNumberInput = ({ label, value, onChange, placeholder }) => {
  const isValid = value && isValidPhoneNumber(value);

  return (
    <FormControl>
      <PhoneInput
    
        country="RU"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        inputComponent={Input}
      />

    </FormControl>
  );
};

export default PhoneNumberInput;
