import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Text, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function RegistrationForm() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const reffererId = new URLSearchParams(location.search).get('ID');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      tenants_id: process.env.REACT_APP_TENANT_ID,
      name: name,
      surname: surname,
      phone: phone,
      uuid: reffererId,
    };

    try {
      await axios.post('https://api.squarefi.app/api:aYQXf2CE/iiko/customers/add', data);
      setStep(2);
    } catch (error) {
      console.error(error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" mt={6}>
        <Spinner />
      </Box>
    );
  }

  if (step === 1) {
    return (
      <Box as="form" onSubmit={handleFormSubmit}>
        <FormControl isRequired>
          <FormLabel>Имя</FormLabel>
          <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Фамилия</FormLabel>
          <Input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Телефон</FormLabel>
          <Input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </FormControl>
        <Button type="submit" mt={4} colorScheme="blue">
        Зарегистрироваться
        </Button>
      </Box>
    );
  } else {
    return (
      <Box>
        <Text mb={4}>Поздравляем! Вы зарегистрированы!</Text>
        <Text mb={4}>Чтобы воспользоваться бонусами - продулжите регистрацию в телеграм-боте</Text>
        <Button as="a" href={process.env.REACT_APP_TG_BOT_LINK} target="_blank" rel="noopener noreferrer" colorScheme="telegram">
        Перейти в телеграм-бот
        </Button>
      </Box>
    );
  }
}

export default RegistrationForm;
