import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TfiAngleLeft } from "react-icons/tfi";

const GoBackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Flex as='b' onClick={goBack} align={'center'}   _hover={{ cursor: "pointer" }}> 
      {" "}
      <TfiAngleLeft mr={2} />
     
      
    </Flex>
  );
};

export default GoBackButton;
