import { useEffect, useState } from "react";

const useProfileData = (TENANT_ID, secret_key) => {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (secret_key) {
          const url = process.env.REACT_APP_MAIN_API_URL + `api:aYQXf2CE/auth/info?tenants_id=${TENANT_ID}&secret_key=${secret_key}`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            setProfileData(data);
          } else {
            console.error("Error fetching profile data:", response.statusText);
          }
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, [TENANT_ID, secret_key]);

  return profileData;
};

export default useProfileData;
