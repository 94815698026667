import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  SimpleGrid,
  ModalContent,
 Hide,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { CartContext } from "./CartContext";
import { useTranslation } from "react-i18next";
const ProductModal = ({ productUUID, isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [modifierAmounts, setModifierAmounts] = useState({});
  const [groupModifierAmounts, setGroupModifierAmounts] = useState({});
  const { addToCart, cart } =
    useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_MAIN_API_URL + `api:aYQXf2CE/nomenclature/products/${productUUID}`,
          {
            params: {
              tenants_id: process.env.REACT_APP_TENANT_ID,
            },
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        setProduct(response.data);

        const initialAmounts = response.data.modifiers.reduce(
          (acc, modifier) => ({
            ...acc,
            [modifier.id]: modifier.defaultAmount,
          }),
          {}
        );
        setModifierAmounts(initialAmounts);

        const initialGroupAmounts = response.data.groupModifiers.reduce(
          (acc, groupModifier) => ({
            ...acc,
            [groupModifier.id]: groupModifier.modifiers.reduce(
              (acc, modifier) => ({
                ...acc,
                [modifier.uuid]: modifier.defaultAmount || 0,
              }),
              {}
            ),
          }),
          {}
        );
        setGroupModifierAmounts(initialGroupAmounts);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    if (isOpen) {
      fetchProduct();
    }
  }, [productUUID, isOpen]);

  if (!product) {
    return null;
  }

  const NutrientInfoBox = ({ value, label }) => (
    <Box
      w={"25%"}

      p={2}
    >
      <Text as="b">{value}</Text>
      <Text fontSize="sm">{label}</Text>
    </Box>
  );


  const handleAmountChange = (modifierId, increment) => {
    setModifierAmounts((prevAmounts) => {
      const prevAmount = prevAmounts[modifierId];
      const modifier = product.modifiers.find((m) => m.id === modifierId);
      let newAmount = prevAmount;

      if (increment && prevAmount < modifier.maxAmount) {
        newAmount = prevAmount + 1;
      } else if (!increment && prevAmount > modifier.minAmount) {
        newAmount = prevAmount - 1;
      }

      return {
        ...prevAmounts,
        [modifierId]: newAmount,
      };
    });
  };

  const handleAddToCart = () => {
    const modifiersList = [];

    for (let [modifierId, amount] of Object.entries(modifierAmounts)) {
      const modifier = product.modifiers.find(
        (modifier) => modifier.id === modifierId
      );
      modifiersList.push({
        uuid: modifierId,
        modifierName: modifier.name,
        amount,
      });
    }

    for (let [groupModifierId, modifiers] of Object.entries(
      groupModifierAmounts
    )) {
      const groupModifier = product.groupModifiers.find(
        (group) => group.id === groupModifierId
      );
      for (let [modifierUUID, amount] of Object.entries(modifiers)) {
        const modifier = groupModifier.modifiers.find(
          (modifier) => modifier.uuid === modifierUUID
        );
        modifiersList.push({
          uuid: modifierUUID,
          modifierName: modifier.name,
          modifierPrice: modifier.sizePrices[0].price.currentPrice,
          groupModifierId,
          groupModifierName: groupModifier.name,
          amount,
        });
      }
    }
    const newProduct = { ...product, modifiersList };

    for (let i = 0; i < quantity; i++) {
      addToCart(newProduct, product.sizePrices[0].price, modifiersList);
    }
    onRequestClose();
  };

  const handleGroupAmountChange = (
    groupModifierId,
    modifierUUID,
    increment
  ) => {
    setGroupModifierAmounts((prevGroupAmounts) => {
      const prevAmount = prevGroupAmounts[groupModifierId][modifierUUID];
      const groupModifier = product.groupModifiers.find(
        (gm) => gm.id === groupModifierId
      );
      let newAmount = prevAmount;
      if (increment && prevAmount < groupModifier.maxAmount) {
        newAmount = prevAmount + 1;
      } else if (!increment && prevAmount > groupModifier.minAmount) {
        newAmount = prevAmount - 1;
      }

      return {
        ...prevGroupAmounts,
        [groupModifierId]: {
          ...prevGroupAmounts[groupModifierId],
          [modifierUUID]: newAmount,
        },
      };
    });
  };

  const calculateTotalPrice = () => {
    let totalPrice = product.sizePrices[0].price.currentPrice;
    for (let [groupModifierId, modifiers] of Object.entries(
      groupModifierAmounts
    )) {
      const groupModifier = product.groupModifiers.find(
        (group) => group.id === groupModifierId
      );
      for (let [modifierUUID, amount] of Object.entries(modifiers)) {
        const modifier = groupModifier.modifiers.find(
          (modifier) => modifier.uuid === modifierUUID
        );
        totalPrice += modifier.sizePrices[0].price.currentPrice * amount;
      }
    }

    return totalPrice;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onRequestClose}
isCentered
      size={["full", "full", "3xl", "4xl", "6xl"]}
  
      
    >
      <ModalOverlay />

      <ModalContent borderRadius={['none', 'none', '3xl']}>
        <ModalCloseButton zIndex={"modal"} colovariant="solid" borderRadius={'full'} background={'gray.50'} opacity={'0.5'}/>
        <ModalBody p={"0"} h={['none', 'none', '398px', '543px', '575px']}>
        <SimpleGrid columns={[1, 1, 2]} spacing={0}><Hide below="md"><Box>
          {product.imageLinks.map((imageLink, index) => (
          
              <Image
                src={`https://5c17f1d920af4a63f00e.ucr.io/-/resize/x1000/-/crop/1:1/center/-/quality/smart_retina/-/enhance/${imageLink}`}
                alt={product.name}
                objectFit="cover"
                objectPosition="center"
                borderLeftRadius={['none', '3xl']}
                h='100%'
                w='100%'
              />
     
          ))}</Box></Hide>
            <Flex flexDirection={'column'}  >  
          <Box  overflowY={'scroll'} overflowX={'hidden'} maxH={['95vh', '95vh', '350px', '495px']}>
          <Hide above="md">
          {product.imageLinks.map((imageLink, index) => (
          
          <Image
            src={`https://5c17f1d920af4a63f00e.ucr.io/-/resize/x1000/-/crop/1:1/center/-/quality/smart_retina/-/enhance/${imageLink}`}
            alt={product.name}
            objectFit="cover"
            objectPosition="center"
            borderTopRadius={['none', 'none', '3xl']}
            maxH='100vw'
            
            w='100%'
          />
 
      ))} </Hide>
      <Box px={7}>
            <Heading py={3}>{product.name}</Heading>
            <Text>{product.description} {product.weight.toFixed(2) * 1000} {t("gramm.short")}</Text>

            <Flex
 
 
>
  <NutrientInfoBox value={product.proteinsFullAmount.toFixed()} label={t("proteins")} />
  <NutrientInfoBox value={product.fatFullAmount.toFixed()} label={t("fat")} />
  <NutrientInfoBox value={product.carbohydratesFullAmount.toFixed()} label={t("сarbohydrates")} />
  <NutrientInfoBox value={product.energyFullAmount.toFixed()} label={t("kkal.short")} />
  
</Flex>

            <Box>
          
                <Text fontWeight="bold" pb={3}>{t("modifiers")}</Text>
                {product.modifiers.map((modifier) => (
                  <Box key={modifier.uuid} pb={3}>
                    <Flex>
                      <Text>{modifier.name}</Text>

                      <Spacer />
                      <ButtonGroup size={"md"} isAttached variant="outline">
                        <Button
                          disabled={
                            modifierAmounts[modifier.id] <= modifier.minAmount
                          }
                          onClick={() => handleAmountChange(modifier.id, false)}
                        >
                          -
                        </Button>
                        <Button>{modifierAmounts[modifier.id]}</Button>
                        <Button
                          disabled={
                            modifierAmounts[modifier.id] >= modifier.maxAmount
                          }
                          onClick={() => handleAmountChange(modifier.id, true)}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </Box>
                ))}
         
            </Box>
            <form>
              {product.groupModifiers.map((groupModifier) => (
                <Box key={groupModifier.uuid}>
                  <Text fontWeight="bold" pb={3}>{groupModifier.name}</Text>

                  {groupModifier.modifiers.map((modifier) => (
                    <Box key={modifier.uuid} pb={3}>
                      <Flex minWidth="max-content" alignItems="center" gap="2">
                        <Text>{modifier.name}</Text>

                        <Text color="gray.600">
                          +{modifier.sizePrices[0].price.currentPrice}
                          {process.env.REACT_APP_CURENCY_SYMBOL}
                        </Text>

                        <Spacer />
                        <ButtonGroup size={"md"} isAttached variant="outline">
                          <Button
                            disabled={
                              groupModifierAmounts[groupModifier.id][
                                modifier.uuid
                              ] <= groupModifier.minAmount
                            }
                            onClick={() =>
                              handleGroupAmountChange(
                                groupModifier.id,
                                modifier.uuid,
                                false
                              )
                            }
                          >
                            -
                          </Button>
                          <Button>
                            {
                              groupModifierAmounts[groupModifier.id][
                                modifier.uuid
                              ]
                            }
                          </Button>
                          <Button
                            disabled={
                              groupModifierAmounts[groupModifier.id][
                                modifier.uuid
                              ] >= groupModifier.maxAmount
                            }
                            onClick={() =>
                              handleGroupAmountChange(
                                groupModifier.id,
                                modifier.uuid,
                                true
                              )
                            }
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      </Flex>
                    </Box>
                  ))}
                </Box>
              ))}
            </form>
            </Box>
          </Box>
          <Flex  px={7} py={7} w='full' position={['fixed', 'fixed', 'inherit']} bottom='0'>        
          <ButtonGroup size="lg" w='full' borderRadius={'2xl'} isAttached shadow={'md'}>
       
          <Button onClick={() => setQuantity(quantity - 1)} borderRadius={'2xl'} w='10%'>-</Button >  
          <Button onClick={handleAddToCart} borderRadius={'2xl'} w='80%'>
            {t("addtocartbutton")} {(cart.find((item) => item.uuid === product.uuid)?.quantity ??
              quantity) * calculateTotalPrice()}
            {process.env.REACT_APP_CURENCY_SYMBOL}
          </Button>
          <Button onClick={() => setQuantity(quantity + 1)} borderRadius={'2xl'} w='10%'>+</Button >
          </ButtonGroup>
         </Flex></Flex>
          </SimpleGrid>
       
   
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;
