import React, { useContext } from "react";

import { CartContext } from "./CartContext";

import "react-dadata/dist/react-dadata.css";
import { Flex, Text, Box, Divider } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";



const CheckoutOrder = (props) => {
  const { t } = useTranslation();

  const { cart } =
    useContext(CartContext);

  return (
    
    <>
    <Box mb={4}> <Text as="b">{t("checkout.order")}</Text></Box>
      {cart.map((item) => {
        const pricePerItem =
          item.sizePrices && item.sizePrices.length > 0
            ? item.sizePrices[0].price.currentPrice
            : 0;
        let totalPrice = pricePerItem * item.quantity;

        if (item.modifiersList && item.modifiersList.length > 0) {
          for (const modifier of item.modifiersList) {
            if (modifier.amount > 0 && modifier.modifierPrice > 0) {
              totalPrice +=
                modifier.modifierPrice * modifier.amount * item.quantity;
            }
          }
        }

        return (
         
          <Box key={item.uuid} mb={1}>
            <Flex justifyContent={"space-between"}>
              <Text >{item.name} </Text>
              <Text >
                {item.quantity} x {totalPrice}
                {process.env.REACT_APP_CURENCY_SYMBOL}
              </Text>{" "}
            </Flex>
            {item.modifiersList && item.modifiersList.length > 0 && (
              <Text fontSize="xs">
                {item.modifiersList.filter(
                  (modifier) =>
                    modifier.amount > 0 && modifier.modifierPrice > 0
                ).length > 0
                  ? 
                    item.modifiersList
                      .filter(
                        (modifier) =>
                          modifier.amount > 0 && modifier.modifierPrice > 0
                      )
                      .map(
                        (modifier) =>
                          ` ${modifier.modifierName} x ${modifier.amount}`
                      )
                      .join(", ")
                  : ""}
              </Text>
            )}{" "}
          </Box>
        );

       
      })}
       <Divider  />
    </>
  );
};

export default CheckoutOrder;
