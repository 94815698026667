import React, { useState } from 'react';
import { Table, Tbody, Tr, Td, Button} from "@chakra-ui/react";
import { format } from 'date-fns'
import ProfileOrderDetailsModal from './ProfileOrderDetailsModal';

const ProfileOrdersTable = ({ data }) => {
  
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setIsOpen(true);
      };
    
      const handleCloseModal = () => {
        setSelectedOrder(null);
        setIsOpen(false);
      };
    return (
        <>
            <Table size='sm' variant={'unstyled'} maxW='340px'>
                
            <Tbody>
  {data
   .sort((a, b) => b.id - a.id)  // Sort in descending order
    .map(order => (
      <Tr key={order.id} >
        <Td>{order.id}</Td>
        <Td>{format(order.created_at, 'dd MM yyyy')}</Td>
        <Td>{order.payments.reduce((sum, payment) => sum + payment.sum, 0)}{process.env.REACT_APP_CURENCY_SYMBOL}</Td>
        <Td> <Button onClick={() => handleOpenModal(order)} variant={'link'}>show more</Button></Td>
      </Tr>
    ))}
</Tbody>
            </Table>

 

      {selectedOrder && (
        <ProfileOrderDetailsModal
          order={selectedOrder}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}

        </>
    );
};

export default ProfileOrdersTable;