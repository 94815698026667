import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Spinner,
  Text,
  HStack,
  PinInput,
  PinInputField,
  useToast,
  Center,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";

import PhoneNumberInput from "./PhoneNumberInput";

const AuthUser = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };


  const handleReceiveOtp = () => {
    setIsLoading(true);

    const params = new URLSearchParams({
      tenants_id: process.env.REACT_APP_TENANT_ID,
      phone: phoneNumber,
    });

    fetch(
      process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/auth/request_otp?" +
        params.toString(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Display the response in the console

        if (data.status === 200) {
          setIsLoading(false);
          setStep(2); // Move to the next step
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleExchangeOtp = () => {
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/auth/exchange_otp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phoneNumber,
          otp: otp,
          tenants_id: process.env.REACT_APP_TENANT_ID
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            console.error("Error response:", data);
            const errorCode = data.code || "Request failed";
            const errorMessage =
              data.message || "Request failed with status " + response.status;
            throw new Error(`${errorCode}: ${errorMessage}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        localStorage.setItem("secret_key", data.key);

        window.location.href = "/profile";
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleGoBack = () => {
    setPhoneNumber("");
    setOtp("");
    setStep(1);
  };

  return (
    <>
      {step === 1 && (
        <Center>
          <Stack spacing={4}>
          
            <Text color={"gray.600"} textAlign="center">
              {t("signin.message")}
            </Text>

            <FormControl id="phone">
              <PhoneNumberInput
                placeholder="+7 (___) ___-__-__"
                value={phoneNumber}
                onChange={handlePhoneChange}
              />
            </FormControl>

            <Button onClick={handleReceiveOtp} isLoading={isLoading}>
              {isLoading ? <Spinner /> : t("next")}
            </Button>
          </Stack>
        </Center>
      )}

      {step === 2 && (
        <Center>
          <Stack spacing={4}>
            <Heading textAlign="center">{t("verify")}</Heading>
            <Text color={"gray.600"} textAlign="center">
              {t("verify.message")} {phoneNumber}
            </Text>

            <Center>
              <HStack spacing={4}>
                <PinInput
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  size={"lg"}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Center>

            <Button
              onClick={handleExchangeOtp}
              isLoading={isLoading}
              align="center"
            >
              {isLoading ? <Spinner /> : t("login")}
            </Button>

            <Box mt={4} align="center">
              <Button variant="link" onClick={handleGoBack}>
                {t("verify.changephone")}
              </Button>
            </Box>
          </Stack>
        </Center>
      )}
    </>
  );
};

export default AuthUser;
