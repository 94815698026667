import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Confetti from 'react-confetti'

const Order = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [order, setOrder] = useState(null);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_MAIN_API_URL + `api:aYQXf2CE/orders/${uuid}`
        );
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    fetchOrder();
  }, [uuid]);

  if (!order) {
    return <Text>Loading order...</Text>;
  }
  const addressParts = [
    { label: t("street"), value: order.address.street.name },
    { label: t("house"), value: order.address.house },
    { label: t("building"), value: order.address.building },
    { label: t("flat"), value: order.address.flat },
    { label: t("floor"), value: order.address.floor },
    { label: t("entrance"), value: order.address.entrance },
    { label: t("doorphone"), value: order.address.doorphone }
  ];
  return (
    <Box>
       <Confetti
          recycle={false}
          width={window.innerWidth}
          height={window.innerHeight}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      <Heading as="h2" size="md">
        Заказ {order.id}
      </Heading>
      <Text>Оформлен: {new Date(order.created_at).toLocaleString()}</Text>

      <Text>
        Получатель: {order.name}, {order.phone}
      </Text>
     

      <Text>Комментарий: {order.order_comment}</Text>

      <Text as='b'>Состав</Text>

      {order.items.map((item) => (
        <Text key={item.productId}>
          {item.name} - {item.amount}{" "}
          {item.modifiers && item.modifiers.length > 0 ? (
            <Text fontSize="sm">
              {item.modifiers.map((modi, index) => (
                <span key={modi.productId}>
                  {modi.name}
                  {index !== item.modifiers.length - 1 ? ", " : ""}
                </span>
              ))}
            </Text>
          ) : null}
        </Text>
      ))}
       <Text>
        Способ получения:{" "}
        {order.order_type === "DeliveryByClient"
          ? t("DeliveryByClient")
          : t("DeliveryByCourier")}
      </Text>

      {order.order_type !== "DeliveryByClient" && (
        <>
          <Text as='b'>{t("address")}</Text>
          <Text>{" "}
  {addressParts
    .filter(part => part.value) // Filter out empty or undefined parts
    .map((part, index) => (
      <React.Fragment key={index}>
        {part.label}: {part.value}
        {index !== addressParts.length - 1 && ", "}
      </React.Fragment>
    ))}
</Text>
  
        </>
      )}

      <Text>Платеж:</Text>
      {order.payments.map((payment) => (
        <Text key={payment.paymentTypeId}>
          Сумма: {payment.sum}, Способ оплаты: {payment.paymentTypeKind}
        </Text>
      ))}

      {/* Render other order details as needed */}
    </Box>
    
  );
};

export default Order;
