import { Box, Button, Flex, useMediaQuery, useDisclosure } from '@chakra-ui/react';

function PaymentModal({ paymentMethod, setPaymentMethod, setPaymentMethodLabel, orderType }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile] = useMediaQuery('(max-width: 768px)'); // You can customize the breakpoint as needed

  const paymentTypes = [
    { value: process.env.REACT_APP_PAYMENT_TYPE_CASH, label: 'Наличные' },
    { value: process.env.REACT_APP_PAYMENT_TYPE_CARD, label: 'Карта онлайн' },
    // { value: process.env.REACT_APP_PAYMENT_TYPE_SBP, label: 'Система быстрых платежей' },
  ];

  const handlePaymentSelection = (paymentTypeValue) => {
    const paymentType = paymentTypes.find((type) => type.value === paymentTypeValue);
    setPaymentMethod(paymentType);
    setPaymentMethodLabel(paymentType.label); // pass label to the new function
    onClose();
  };

  // Filter payment types based on the order type
  const filteredPaymentTypes = orderType === 'DeliveryByClient'
    ? paymentTypes // Show all payment types for DeliveryByClient
    : paymentTypes.filter((paymentType) => paymentType.value !== process.env.REACT_APP_PAYMENT_TYPE_CASH);

  return (
    <>
      <Flex direction={isMobile ? 'column' : 'row'} gap={4}>
        {filteredPaymentTypes.map((paymentType, index) => {
          const isSelected = paymentMethod?.value === paymentType.value;
          return (
            <Button key={paymentType.value}
                    onClick={() => handlePaymentSelection(paymentType.value)}
                  variant={isSelected ? 'outline' : 'solid'}
                  borderColor={isSelected ? 'red' : 'none'}
          
                  >
              {paymentType.label}
            </Button>
          );
        })}
      </Flex>
    </>
  );
}

export default PaymentModal;
