import { Heading, Box, SimpleGrid} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";

const API_URL =
process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/nomenclature";
const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/groups`, {
        params: { tenants_id: TENANT_ID },
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
      .then((res) => {
        const sortedCategories = res.data.sort((a, b) => a.order - b.order);
        const uniqueCategories = Array.from(
          new Set(sortedCategories.map((category) => category.uuid))
        ).map((uuid) =>
          sortedCategories.find((category) => category.uuid === uuid)
        );
        setCategories(uniqueCategories);
      });
  }, []);
console.log(categories)
  return (
    <>
      <Helmet></Helmet>
      <SimpleGrid minChildWidth={[150, 200, 250, 250]} spacing={4}>
        {categories &&
          categories.map((category) => (
            <Link
              to={`/groups/${category.uuid}`}
              key={category.uuid}
              textDecoration="none"
              _hover={{ textDecoration: "underline" }}
            >
     <Box
  aspectRatio="1/1"
  boxShadow="xs"
  borderRadius="3xl"
  bgSize="cover"
  bgPosition="center"
  bgImage={
    category.imageLinks[0] 
      ? `url(https://5c17f1d920af4a63f00e.ucr.io/-/resize/x640/-/crop/4:3/center/-/quality/smart_retina/-/enhance/${category.imageLinks[0]})` 
      : ""
  }
 
>
               
                <Box
                  p={6}
                  h={"100%"}
                 
                >
                  <Box>
                    <Heading color="white" size={"sm"}>
                      {category.name}
                    </Heading>
                  </Box>
                </Box>
              </Box>
            </Link>
          ))}
      </SimpleGrid>
    </>
  );
};

export default Categories;
