import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import axios from 'axios';

const OrderTable = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await axios.get(process.env.REACT_APP_MAIN_API_URL + 'api:aYQXf2CE/orders', {
        params: {
          tenants_id: 1
        }
      });
      
      const ordersWithDates = response.data.map((order) => {
        return { 
          ...order, 
          created_at: new Date(order.created_at).toLocaleString()
        };
      });

      setOrders(ordersWithDates);
    };

    fetchOrders();
  }, []);

  const handleRowClick = (uuid) => {
    navigate(`/order/${uuid}`);
  }

  return (
    <Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Order ID</Th>
            <Th>Created At</Th>
            <Th>Name</Th>
            <Th>Phone</Th>
            <Th>Order Type</Th>
            <Th>IIKO STATE</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => (
            <Tr key={order.id} onClick={() => handleRowClick(order.uuid)}>
              <Td>{order.id}</Td>
              <Td>{order.created_at}</Td>
              <Td>{order.name}</Td>
              <Td>{order.phone}</Td>
              <Td>{order.order_type}</Td>
              <Td>{order.iiko_state}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default OrderTable;
