import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import AuthUser from './AuthUser';

const AuthUserModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{base: 'full', lg: 'lg'  }} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={'2xl'}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AuthUser />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AuthUserModal;