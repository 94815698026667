import React, { useEffect, useState } from "react";
import { Button, Box, Text, Icon } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import useProfileData from "./hooks/useProfileData";
import AuthUserModal from './AuthUserModal'; // make sure this import path is correct

const AuthButton = () => {
  const secret_key = localStorage.getItem("secret_key");
  const [reloadCounter, setReloadCounter] = useState(0);
  const profileData = useProfileData(
    process.env.REACT_APP_TENANT_ID,
    secret_key,
    reloadCounter
  );
  const limitText = (text) => {
    if (text && text.length) {
      if (text.length <= 10) {
        return text; // If the text is already 10 characters or less, return it as is
      } else {
        const truncatedText = text.substring(0, 10); // Take the first 10 characters
  
        const lastSpaceIndex = truncatedText.lastIndexOf(" ");
        if (lastSpaceIndex !== -1 && lastSpaceIndex !== 9) {
          return truncatedText.substring(0, lastSpaceIndex); // Cut the text at the last space
        } else {
          return truncatedText; // If no space is found or the last space is at index 9, return the truncated text without appending "..."
        }
      }
    } else {
      return ""; // Return an empty string if the text is undefined or null
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  
  const name = profileData?.name;

  const location = useLocation();

  const handleReload = () => {
    setReloadCounter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    const timeout = setTimeout(handleReload, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [location]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      {secret_key ? (
        <Link to="/profile">
          <Button
            display="flex"
            align={"center"}
            flexDirection="row"
            variant="unstyled"
            h="full"
          >
            <Icon as={VscAccount} boxSize={6} mr={2} />
            <Box align="left">
              <Text>{limitText(name)}</Text>
            </Box>
          </Button>
        </Link>
      ) : (
        <>
          <Button 
            display="flex" 
            h="full" 
            align={"center"} 
            variant="unstyled" 
            onClick={handleOpen}
          >
            <Icon as={VscAccount} boxSize={5} mr={2}/>
            <Box align="left">
              <Text>Войти</Text>
            </Box>
          </Button>
          <AuthUserModal isOpen={isOpen} onClose={handleClose} />
        </>
      )}
    </>
  );
};

export default AuthButton;

