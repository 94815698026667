import { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  List,
  ListItem,
  Link as ChakraLink,
  Box,
  Icon,
  ModalCloseButton
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { RiMenuLine } from "react-icons/ri";

const API_URL = process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/nomenclature";
const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const SideMenu = ({ isOpen, onClose }) => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/groups`, {
        params: { tenants_id: TENANT_ID },
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
      .then((res) => {
        const sortedCategories = res.data.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
      });
  }, []);

  const handleNavigate = (url) => {
    onClose();
    setTimeout(() => {
      navigate(url);
    }, 250); // Delay navigation until after the drawer closes
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay />
      <ModalContent borderRadius={'2xl'} position={'absolute'} mx={6} left='0px'>
        <ModalCloseButton />
        <ModalBody >
    
          <List spacing={4}>
            {categories.map((category) => (
              <ListItem key={category.uuid}>
                <ChakraLink
                  onClick={() => handleNavigate(`/groups/${category.uuid}`)}
                  textDecoration="none"
                  color="black"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Text>{category.name}</Text>
                </ChakraLink>
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SideMenu;
