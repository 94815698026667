// 1. Import statements
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CartContext } from "./CartContext";
import { AddressSuggestions } from "react-dadata";
import { useTranslation } from "react-i18next";

import "react-dadata/dist/react-dadata.css";
import useProfileData from "./hooks/useProfileData";

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  SimpleGrid,
  Stepper,
  Step,
  StepTitle,
  Text,
  Textarea,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";

import CheckoutOrder from "./CheckoutOrder";
import TimeModal from "./TimeModal";
import CheckoutPayment from "./CheckoutPayment";
import CheckoutProfile from "./CheckoutProfile";
import PaymentButton from "./PaymentButton";

// 2. Component declaration
const Checkout = (props) => {
  // 2.1 Translation
  const { t } = useTranslation();

  // 2.2 State variables
  const { cart, clearCart } = useContext(CartContext);
  const toast = useToast();
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [flat, setFlat] = useState("");
  const [entrance, setEntrance] = useState("");
  const [floor, setFloor] = useState("");
  const [doorphone, setDoorphone] = useState("");
  const [address_comment, setAddressComment] = useState("");
  const [order_comment, setOrderComment] = useState("");
  const [address, setAddress] = useState(null);
  const [orderType, setOrderType] = useState("DeliveryByClient");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [bonusesBool, setBonusesBool] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [sliderValue, setSliderValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [qrOrderId, setQrOrderId] = useState(null);
  const [qrAmount, setQrAmount] = useState(null);
  const [qrSuccess, setQrSuccess] = useState(false);
  const [paymentMethodLabel, setPaymentMethodLabel] = useState("");

  // 2.3 Constants
  const TENANT_ID = process.env.REACT_APP_TENANT_ID;
  const DELIVERY_PRODUCT = process.env.REACT_APP_DELIVERY_PRODUCT;
  const secret_key = localStorage.getItem("secret_key");
  const filterLocations = JSON.parse(process.env.REACT_APP_FILTER_LOCATIONS);
  const profileData = useProfileData(TENANT_ID, secret_key);
  const totalBonusBalance =
    profileData && profileData.walletBalances
      ? profileData.walletBalances.reduce(
          (sum, balance) => sum + balance.balance,
          0
        )
      : 0;
console.log ("deliveryProduct" , DELIVERY_PRODUCT)
  // 3. Functions, Event Handlers
  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const calculateTotal = () => {
    let total = 0;

    for (const item of cart) {
      const pricePerItem =
        item.sizePrices && item.sizePrices.length > 0
          ? item.sizePrices[0].price.currentPrice
          : 0;
      let totalPrice = pricePerItem * item.quantity;

      if (item.modifiersList && item.modifiersList.length > 0) {
        for (const modifier of item.modifiersList) {
          if (modifier.amount > 0 && modifier.modifierPrice > 0) {
            totalPrice +=
              modifier.modifierPrice * modifier.amount * item.quantity;
          }
        }
      }

      total += totalPrice;
    }

    return total;
  };

  const calculateTotalWithDelivery = () => {
    return Number(calculateTotal()) + Number(deliveryPrice);
  };

  const handleSubmitClick = (event) => {
    if (!name) {
      toast({
        title: "Ошибка.",
        description: "Укажите имя",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!phone) {
      toast({
        title: "Ошибка.",
        description: "Укажите телефон",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (
      orderType !== "DeliveryByClient" &&
      (!address || !address.data || !address.data.house)
    ) {
      toast({
        title: "Ошибка.",
        description: "Укажите номер дома",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!paymentMethod.value) {
      toast({
        title: "Ошибка.",
        description: "Выберете метод оплаты",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    handleSubmit(event);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setIsSubmitting(true); // Set isSubmitting to true when checkout button is clicked

    const order = {
      tenants_id: process.env.REACT_APP_TENANT_ID,
      stores_id: process.env.REACT_APP_STORE_ID,
      name: name || "NONAME",
      phone: phone,
      order_type: orderType,
      payments: [
        {
          paymentTypeKind: "Card",
          sum: calculateTotalWithDelivery() - sliderValue,
          paymentTypeId: paymentMethod.value,
          isProcessedExternally: false,
          isPrepay: false,
          isFiscalizedExternally: false,
        },
        ...(bonusesBool && sliderValue > 1
          ? [
              {
                paymentTypeKind: "IikoCard",
                sum: sliderValue,
                paymentTypeId: process.env.REACT_APP_PAYMENT_TYPE_IIKOCARD,
                paymentAdditionalData: {
                  type: "IikoCard",
                  credential: phone,
                  searchScope: "Phone",
                },
              },
            ]
          : []),
      ],
      address: {
        street: {
          classifierId: address?.data.street_kladr_id || "",
          name: address?.data.street || "",
        },
        house: address?.data.house || "",
        building: address?.data.block || "",
        flat: flat,
        entrance: entrance,
        floor: floor,
        doorphone: doorphone,
      },
      address_comment: address_comment,
      order_comment: order_comment,
      complete_before: selectedDateTime ? `${selectedDateTime}:00.000` : null,
      items: [
        ...cart.map((item) => ({
          productId: item.uuid,
          amount: item.quantity,
          type: "Product",
          modifiers:
            item.modifiersList && item.modifiersList.length > 0
              ? item.modifiersList
                  .filter((modifier) => modifier.amount > 0)
                  .map((modifier) => ({
                    productId: modifier.uuid,
                    amount: modifier.amount,
                    productGroupId: modifier.groupModifierId,
                  }))
              : [],
        })),
        ...(orderType === "DeliveryByCourier"
          ? [
              {
                productId: DELIVERY_PRODUCT,
                amount: 1,
                type: "Product",
                price: deliveryPrice,
              },
            ]
          : []),
      ],
    };

    fetch(process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/delivery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setIsSubmitting(false);
        console.log("Raw response:", data); // Log raw data here

        if (
          data.result.orderInfo.creationStatus === "Success" ||
          data.result.orderInfo.creationStatus === "InProgress"
        ) {
          setQrOrderId(data.uuid);
          setQrAmount(calculateTotal() + deliveryPrice);
          setShowConfetti(true);
          clearCart();
          window.location.href = "/order/" + data.uuid;
        } else if (data.result.orderInfo.creationStatus === "Error") {
          toast({
            title: "Error",
            description: data.result.orderInfo.errorInfo.code,
            status: "error",
            duration: 9000,
            isClosable: true,
          }); // Show an error toast
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast({
          title: "Error: " + error.message,
          description: "Your order is not recieved",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsSubmitting(false);
        setLoading(false);
      });
  };

  const displayBalance =
    totalBonusBalance > calculateTotal() ? calculateTotal() : totalBonusBalance;

  // 4. useEffects

  useEffect(() => {
    if (profileData) {
      setPhone(profileData.phone);
      setName(profileData.name);
    }
  }, [profileData]);

  useEffect(() => {
    if (cart.length > 0 && orderType === "DeliveryByCourier" && address) {
      const fetchDeliveryPrice = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/delivery/price",
            {
              params: {
                tenants_id: process.env.REACT_APP_TENANT_ID,
                // order_total: calculateTotal(),
                destination: {
                  street: address.data.street || "",
                  city: address.data.city || "",
                  house: address.data.house || "",
                  lat: address.data.geo_lat || "",
                  lon: address.data.geo_lon || "",
                },
              },
            }
          );

          if (response.data) {
            if ("price" in response.data) {
              setDeliveryPrice(response.data.price);
              setLoading(false);
            } else {
              console.error(
                "Failed to fetch delivery price: price not in response"
              );
              setLoading(false);
            }
          }
        } catch (error) {
          console.error("Failed to fetch prices:", error);
          setLoading(false);
        }
      };

      fetchDeliveryPrice();
    }
  }, [cart, address, orderType]);

  return (
    <>
      <Grid
        templateColumns={[
          "repeat(4, 1fr)",
          "repeat(8, 1fr)",
          "repeat(12, 1fr)",
          "repeat(12, 1fr)",
        ]}
        gap={4}
      >
        <GridItem colSpan={[4, 8, 7, 7]}>
          <>
            <form onSubmit={handleSubmit}>
              <Stepper orientation="vertical">
                <Step w="100%">
                  <Flex direction={"column"} w="100%">
                    <StepTitle>1. Контактные данные</StepTitle>

                    <CheckoutProfile
                      profileData={profileData}
                      handlePhoneChange={handlePhoneChange}
                      setName={setName}
                      bonusesBool={bonusesBool}
                      setBonusesBool={setBonusesBool}
                      displayBalance={displayBalance}
                      sliderValue={sliderValue}
                      setSliderValue={setSliderValue}
                    />
                  </Flex>
                </Step>

                <Step w="100%" mt={4}>
                  <Box w="100%">
                    <StepTitle>2. Способ получения</StepTitle>

                    <FormControl isRequired mb={4} mt={4}>
                      <ButtonGroup variant="outline" spacing="2">
                        <Button
                          value="DeliveryByClient"
                          onClick={() => {
                            setOrderType("DeliveryByClient");
                            setPaymentMethod(
                              process.env.REACT_APP_PAYMENT_TYPE_CARD
                            );
                          }}
                          borderColor={
                            orderType === "DeliveryByClient" ? "red" : null
                          }
                          borderWidth={
                            orderType === "DeliveryByClient" ? "1px" : null
                          }
                        >
                          {t("DeliveryByClient")}
                        </Button>
                        <Button
                          value="DeliveryByCourier"
                          onClick={() => {
                            setOrderType("DeliveryByCourier");
                            setPaymentMethod(
                              process.env.REACT_APP_PAYMENT_TYPE_CARD
                            );
                          }}
                          borderColor={
                            orderType === "DeliveryByCourier" ? "red" : null
                          }
                          borderWidth={
                            orderType === "DeliveryByCourier" ? "1px" : null
                          }
                        >
                          {t("DeliveryByCourier")}
                        </Button>
                      </ButtonGroup>
                    </FormControl>

                    {orderType === "DeliveryByCourier" && (
                      <>
                        <Grid
                          templateColumns={["repeat(2, 1fr)", "repeat(5, 1fr)"]}
                          templateRows={["repeat(2, 1fr)", "repeat(1, 1fr)"]}
                          gap={2}
                          mb={2}
                        >
                          <GridItem colSpan={[2, 5]} rowSpan={[1]}>
                            <FormControl isInvalid={!address?.data.house}>
                              <AddressSuggestions
                                token="42c58f953811da12f6dea621107df96f5bbe130f"
                                onChange={handleAddressChange}
                                filterLocations={filterLocations}
                                inputProps={{
                                  placeholder: "Улица и номер дома ",
                                }}
                              />
                              <FormErrorMessage>
                                Укажите номер дома в строке выше.
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                          {/* <GridItem colSpan={1}>
                            <FormControl >
                              <Input
                                type="text"
                                name="house"
                                placeholder={t("house")}
                                value={address?.data.house || ""}
                                isRequired
                                isInvalid={!address?.data.house}
                              />
                               
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <Input
                                type="text"
                                name="building"
                                placeholder={t("building")}
                                value={address?.data.block || ""}
                              />
                            </FormControl>
                          </GridItem> */}
                        </Grid>
                        <SimpleGrid columns={[2, 2, 4, 4]} spacing={2} mb={2}>
                          <FormControl>
                            <Input
                              type="text"
                              name="flat"
                              placeholder={t("flat")}
                              value={flat}
                              onChange={(e) => setFlat(e.target.value)}
                              maxLength={5}
                            />
                          </FormControl>

                          <FormControl>
                            <Input
                              type="text"
                              name="entrance"
                              placeholder={t("entrance")}
                              value={entrance}
                              onChange={(e) => setEntrance(e.target.value)}
                              maxLength={4}
                            />
                          </FormControl>

                          <FormControl>
                            <Input
                              type="number"
                              name="floor"
                              placeholder={t("floor")}
                              value={floor}
                              onChange={(e) => setFloor(e.target.value)}
                              maxLength={2}
                            />
                          </FormControl>

                          <FormControl>
                            <Input
                              type="number"
                              name="doorphone"
                              placeholder={t("doorphone")}
                              value={doorphone}
                              onChange={(e) => setDoorphone(e.target.value)}
                              maxLength={4}
                            />
                          </FormControl>
                        </SimpleGrid>

                        <FormControl mb={2}>
                          <Textarea
                            type="text"
                            name="addressComment"
                            placeholder={t("addresscomment")}
                            value={address_comment}
                            onChange={(e) => setAddressComment(e.target.value)}
                          />
                        </FormControl>
                      </>
                    )}
                    {/* {orderType === "DeliveryByClient" && (
                      <Box
                        onClick={() => setIsOpen(true)}
                        rounded={"md"}
                        border={"1px"}
                        borderColor={"gray.200"}
                        mb={4}
                        w="full"
                        align={"center"}
                        size={"lg"}
                        _hover={{ cursor: "pointer", boxShadow: "lg" }}
                        py={12}
                      >
                        Выбрать точку самовывоза
                      </Box>
                    )} */}

                    <ButtonGroup variant="outline" mb={4}>
                      <Button
                        onClick={() => setSelectedDateTime(null)}
                        isActive={selectedDateTime === null}
                      >
                        Сейчас
                      </Button>

                      <TimeModal
                        startTime="10:00"
                        endTime="23:30"
                        selectedDateTime={selectedDateTime}
                        setSelectedDateTime={setSelectedDateTime}
                        isActive={selectedDateTime !== null}
                      />
                    </ButtonGroup>

                    <FormControl>
                      <Textarea
                        type="text"
                        name="orderComment"
                        placeholder={t("ordercomment")}
                        value={order_comment}
                        onChange={(e) => setOrderComment(e.target.value)}
                      ></Textarea>
                    </FormControl>
                  </Box>
                </Step>
                <Step w="100%" mt={4}>
                  <Box w="100%">
                    <StepTitle>3. Подтверждение и оплата</StepTitle>

                    <Box mt={4}>
                      <CheckoutPayment
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        setPaymentMethodLabel={setPaymentMethodLabel}
                        orderType={orderType}
                      />
                    </Box>
                  </Box>
                </Step>
              </Stepper>
            </form>
          </>
        </GridItem>
        <GridItem colStart={[1, 1, 9, 9]} colEnd={[5, 9, 13, 13]}>
          <Box
            p={[0, 0, 6, 6]}
            shadow={["none", "none", "xl"]}
            borderRadius={"xl"}
          >
            <CheckoutOrder />

            <Box my={4}>
              <HStack justify="space-between">
                <Text>{t("cart.items")}:</Text>{" "}
                <Text>
                  {calculateTotal()} {process.env.REACT_APP_CURENCY_SYMBOL}{" "}
                </Text>{" "}
              </HStack>
              {address && orderType === "DeliveryByCourier" && (
                <>
                  <HStack justify="space-between">
                    <Text>{t("cart.delivery")}:</Text>{" "}
                    <Text>
                      {deliveryPrice} {process.env.REACT_APP_CURENCY_SYMBOL}{" "}
                    </Text>{" "}
                  </HStack>
                </>
              )}
              {bonusesBool === true && (
                <React.Fragment>
                  <HStack justify="space-between">
                    <Text>{t("cart.discount")}:</Text>
                    <Text>
                      -{sliderValue} {process.env.REACT_APP_CURENCY_SYMBOL}
                    </Text>
                  </HStack>
                  <HStack justify="space-between" as="b">
                    <Text>{t("cart.total")}:</Text>
                    <Text>
                      {calculateTotalWithDelivery() - sliderValue}{" "}
                      {process.env.REACT_APP_CURRENCY_SYMBOL}
                    </Text>
                  </HStack>
                </React.Fragment>
              )}
              {bonusesBool === false && (
                <HStack justify="space-between" as="b">
                  <Text>{t("cart.total")}:</Text>
                  <Text>
                    {calculateTotalWithDelivery()}{" "}
                    {process.env.REACT_APP_CURENCY_SYMBOL}
                  </Text>
                </HStack>
              )}
              <HStack justify="space-between">
                <Text>Способ оплаты:</Text> <Text>{paymentMethodLabel} </Text>{" "}
              </HStack>
            </Box>

            <PaymentButton
              type={paymentMethod}
              paymentMethod={paymentMethod}
              orderType={orderType}
              handleSubmit={handleSubmitClick}
              loading={loading}
            ></PaymentButton>

            {/* <PaymentButton
              type={process.env.REACT_APP_PAYMENT_TYPE_SBP}
              paymentMethod={paymentMethod}
              orderType={orderType}
              handleSubmit={handleSubmit}
              loading={loading}
            >
              Оплатить
              <Image
                src="../assets/svg/SBP_logo.svg"
                alt="SBP Logo"
                ml={3}
                boxSize="50px"
              />
            </PaymentButton>

            {paymentMethod.value === process.env.REACT_APP_PAYMENT_TYPE_SBP &&
              qrOrderId &&
              qrAmount && (
                <TinkoffSBP
                  OrderId={qrOrderId}
                  amount={qrAmount}
                  onSuccess={setQrSuccess}
                />
              )} */}
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

export default Checkout;
