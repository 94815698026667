import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  VStack,
  Text,
  Input,
  Button,
  Center,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Stack,
  Box,
} from "@chakra-ui/react";
import PhoneNumberInput from "./PhoneNumberInput";

const CustomerInfo = () => {
  const [phone, setPhone] = useState("");
  const [customerInfo, setCustomerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [walletComments, setWalletComments] = useState([]);
  const [walletSums, setWalletSums] = useState([]);
  const [walletCommentsChargeOff, setWalletCommentsChargeOff] = useState([]);
  const [walletSumsChargeOff, setWalletSumsChargeOff] = useState([]);

  useEffect(() => {
    if (customerInfo) {
      setWalletComments(new Array(customerInfo.walletBalances.length).fill(""));
      setWalletSums(new Array(customerInfo.walletBalances.length).fill(0));
      setWalletCommentsChargeOff(
        new Array(customerInfo.walletBalances.length).fill("")
      );
      setWalletSumsChargeOff(
        new Array(customerInfo.walletBalances.length).fill(0)
      );
    }
  }, [customerInfo]);

  const fetchCustomerInfo = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/iiko/customers/info",
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
          params: {
            tenants_id: process.env.REACT_APP_TENANT_ID,
            phone: phone,
          },
        }
      );
      setCustomerInfo(response.data);
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    fetchCustomerInfo();
  };

  const handleTopUp = async (walletId, index) => {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/iiko/customers/topup",
        {
          tenants_id: process.env.REACT_APP_TENANT_ID,
          customerId: customerInfo.id,
          walletId: walletId,
          comment: walletComments[index],
          sum: walletSums[index],
        }
      );
      if (response.status !== 200) {
        throw new Error("TopUp request failed");
      }
      // Fetch customer data again after successful topup
      fetchCustomerInfo();
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleChargeOff = async (walletId, index) => {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/iiko/customers/chargeoff",
        {
          tenants_id: process.env.REACT_APP_TENANT_ID,
          customerId: customerInfo.id,
          walletId: walletId,
          comment: walletCommentsChargeOff[index],
          sum: walletSumsChargeOff[index],
        }
      );
      if (response.status !== 200) {
        throw new Error("ChargeOff request failed");
      }
      // Fetch customer data again after successful chargeoff
      fetchCustomerInfo();
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };
console.log(phone);
  return (
    <VStack spacing="4">
      <form onSubmit={handleSubmit}>
        <FormControl id="phone">
          <FormLabel>Customer phone</FormLabel>
          
         
          <FormHelperText>
            Enter the customer phone number to fetch their details
          </FormHelperText>
        </FormControl>
        <PhoneNumberInput  type="tel"
            name="phone"
            value={phone}
            onChange={setPhone}
            title="Phone number must start with a '+' and be at least 8 digits"
            required/>
        <Center>
          <Button type="submit" isLoading={loading}>
            Fetch Customer Info
          </Button>
        </Center>
      </form>
      {loading && <Box></Box>}
      {error && (
        <Stack spacing="2">
          <Text color="red.500">{error}</Text>
          <Text>If the problem persists, contact support.</Text>
        </Stack>
      )}
      {customerInfo && (
        <Box>
          <Text fontWeight="bold" fontSize="xl">
            Customer Info
          </Text>
          <Text>Name: {customerInfo.name ?? "N/A"}</Text>
          <Text>Email: {customerInfo.email ?? "N/A"}</Text>
          <Text>Phone: {customerInfo.phone ?? "N/A"}</Text>
          <Text>Id: {customerInfo.id}</Text>
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mt="4">
            <Box bg="gray.100" px="2" py="2">
              <Text fontWeight="bold" fontSize="xl">
                Wallet Info
              </Text>
            </Box>

            {customerInfo.walletBalances.map((wallet, index) => (
              <Box key={index} p="4" borderBottomWidth="1px">
                <Text fontWeight="bold">Name: {wallet.name}</Text>
                <Text>Id: {index + 1}</Text>
                <Text>Balance: {wallet.balance}</Text>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTopUp(wallet.id, index);
                  }}
                >
                  <FormControl id={`coment-${index}`} mt="2">
                    <FormLabel> Comment:</FormLabel>
                    <Input
                      type="text"
                      value={walletComments[index]}
                      onChange={(e) => {
                        const newComments = [...walletComments];
                        newComments[index] = e.target.value;
                        setWalletComments(newComments);
                      }}
                    />
                    <FormErrorMessage>
                      Please enter a valid number
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl id={`sum-${index}`} mt="2">
                    <FormLabel>Top Up Sum</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      value={walletSums[index]}
                      onChange={(e) => {
                        const newSums = [...walletSums];
                        newSums[index] = parseFloat(e.target.value);
                        setWalletSums(newSums);
                      }}
                      placeholder="Enter amount"
                      required
                    />
                    <FormErrorMessage>
                      Please enter a valid number
                    </FormErrorMessage>
                  </FormControl>
                  <Button type="submit" isLoading={loading} mt="2">
                    Top Up
                  </Button>
                </form>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleChargeOff(wallet.id, index);
                  }}
                >
                  <FormControl id={`coment-${index}-chargeoff`} mt="2">
                    <FormLabel> Comment:</FormLabel>
                    <Input
                      type="text"
                      value={walletCommentsChargeOff[index]}
                      onChange={(e) => {
                        const newCommentsChargeOff = [
                          ...walletCommentsChargeOff,
                        ];
                        newCommentsChargeOff[index] = e.target.value;
                        setWalletCommentsChargeOff(newCommentsChargeOff);
                      }}
                    />
                  </FormControl>
                  <FormControl id={`sum-${index}-chargeoff`} mt="2">
                    <FormLabel>Chargeoff Sum</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      value={walletSumsChargeOff[index]}
                      onChange={(e) => {
                        const newSumsChargeOff = [...walletSumsChargeOff];
                        newSumsChargeOff[index] = parseFloat(e.target.value);
                        setWalletSumsChargeOff(newSumsChargeOff);
                      }}
                      required
                    />
                    <FormErrorMessage>
                      Please enter a valid number
                    </FormErrorMessage>
                  </FormControl>
                  <Button type="submit" isLoading={loading} mt="2">
                    Chargeoff
                  </Button>
                </form>
              </Box>
            ))}
          </Box>{" "}
        </Box>
      )}
    </VStack>
  );
};

export { CustomerInfo };
