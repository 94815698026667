import React, { useContext, useState } from "react";

import { CartContext } from "./CartContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import "react-dadata/dist/react-dadata.css";
import {
  Flex,
  Text,
  Button,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CloseButton,
  Modal,
  ButtonGroup,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { IconButton } from "@chakra-ui/react";
import { AddIcon, MinusIcon, DeleteIcon } from "@chakra-ui/icons";

const Cart = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { cart, incrementQuantity, decrementQuantity, emptyCart, clearCart } =
    useContext(CartContext);

  const calculateTotal = () => {
    let total = 0;
    for (const item of cart) {
      const pricePerItem =
        item.sizePrices && item.sizePrices.length > 0
          ? item.sizePrices[0].price.currentPrice
          : 0;
      let totalPrice = pricePerItem * item.quantity;

      if (item.modifiersList && item.modifiersList.length > 0) {
        for (const modifier of item.modifiersList) {
          if (modifier.amount > 0 && modifier.modifierPrice > 0) {
            totalPrice +=
              modifier.modifierPrice * modifier.amount * item.quantity;
          }
        }
      }

      total += totalPrice;
    }
    return total;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setIsSubmitting(true); 
    navigate("/checkout");
    props.closeModal();
  };

  const handleEmptyCart = () => {
    emptyCart();
  };

  return (
    <Modal
      size={["full", "lg"]}
      scrollBehavior={"inside"}
      blockScrollOnMount={true}
      isOpen={props.modalOpen}
      onClose={props.closeModal}
      motionPreset="none"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w={["full", "lg"]}  borderRadius={'2xl'} >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          {t("cart")}
          <CloseButton size="lg" onClick={props.closeModal} />
        </ModalHeader>
        <ModalBody>
          {cart.map((item) => {
            const pricePerItem =
              item.sizePrices && item.sizePrices.length > 0
                ? item.sizePrices[0].price.currentPrice
                : 0;
            let totalPrice = pricePerItem * item.quantity;

            if (item.modifiersList && item.modifiersList.length > 0) {
              for (const modifier of item.modifiersList) {
                if (modifier.amount > 0 && modifier.modifierPrice > 0) {
                  totalPrice +=
                    modifier.modifierPrice * modifier.amount * item.quantity;
                }
              }
            }

            return (
              <Box key={item.uuid}>
                <Flex  gap={4} py={2}>
                  <Flex w={"23%"}>
                    <Image
                      src={item.imageLinks[0]}
                      alt={item.name}
                      borderRadius="2xl"
                      objectFit="cover"
                      objectPosition="center"
                      w={"100%"}
                      aspectRatio={1} //Set the aspect ratio to 4:5
                    />{" "}
                  </Flex>

                  <Flex
                    flexDir="column"
                    justifyContent={"space-evenly"}
                    w={"73%"}
                  >
                    <Box>
                      <Text as="b">{item.name} </Text>
                      {item.modifiersList && item.modifiersList.length > 0 && (
                        <Text fontSize="xs">
                          {item.modifiersList.filter(
                            (modifier) =>
                              modifier.amount > 0 && modifier.modifierPrice > 0
                          ).length > 0
                            ? "+" +
                              item.modifiersList
                                .filter(
                                  (modifier) =>
                                    modifier.amount > 0 &&
                                    modifier.modifierPrice > 0
                                )
                                .map(
                                  (modifier) =>
                                    ` ${modifier.modifierName} x ${modifier.amount}`
                                )
                                .join(", ")
                            : ""}
                        </Text>
                      )}{" "}
                    </Box>
                    <Box>
                      <Flex alignItems="center" pt={2}>
                        <Box>
                          <ButtonGroup size={"xs"} isAttached>
                            <IconButton
                              onClick={() => incrementQuantity(item)}
                              icon={<AddIcon size={"sm"} />}
                              aria-label="Add Quantity"
                            />

                            <IconButton
                              isDisabled
                              icon={<Box>{item.quantity}</Box>}
                              aria-label="Quantity"
                            />

                            <IconButton
                              onClick={() => decrementQuantity(item)}
                              icon={<MinusIcon size={"sm"} />}
                              aria-label="Decrement Quantity"
                            />
                          </ButtonGroup>
                        </Box>
                        <Spacer />
                        <Box>
                          <Text>
                            {totalPrice}
                            {process.env.REACT_APP_CURENCY_SYMBOL}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </ModalBody>

        <ModalFooter flexDir={"column"} alignItems={"left"}>
          <Flex py={6}>
            <Text as={"b"}>{t("cart.total")}:</Text> <Spacer />{" "}
            <Text as={"b"}>
              {calculateTotal()}
              {process.env.REACT_APP_CURENCY_SYMBOL}{" "}
            </Text>
          </Flex>

          <Button onClick={handleSubmit} size={"lg"} borderRadius={'xl'}>
            {t("cart.checkout")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Cart;
