import React, { useState, useContext, useEffect } from "react";

import Cart from "./Cart";
import { CartContext } from "./CartContext";
import SideMenu from "./SIdeMenu";
import {
  Hide,
  Box,
  Button,
  Text,
  Flex,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RiShoppingCartLine, RiMenuLine } from "react-icons/ri";
import AuthButton from "./AuthButton";
const Navigation = () => {
  const { t } = useTranslation();
  const { cart } = useContext(CartContext);
  const totalQuantity = cart.reduce((total, item) => total + item.quantity, 0);
  const calculateTotal = () => {
    let total = 0;
    for (const item of cart) {
      const pricePerItem =
        item.sizePrices && item.sizePrices.length > 0
          ? item.sizePrices[0].price.currentPrice
          : 0;
      let totalPrice = pricePerItem * item.quantity;

      if (item.modifiersList && item.modifiersList.length > 0) {
        for (const modifier of item.modifiersList) {
          if (modifier.amount > 0 && modifier.modifierPrice > 0) {
            totalPrice +=
              modifier.modifierPrice * modifier.amount * item.quantity;
          }
        }
      }

      total += totalPrice;
    }
    return total;
  };
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  const handleOpenSideMenu = () => {
    setSideMenuOpen(true);
  };

  const handleCloseSideMenu = () => {
    setSideMenuOpen(false);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      // Fetch the customer profile data here
      // You can use the same logic you have in the useProfileData hook or any other approach you prefer
      // Update the relevant state or context with the fetched data
    };

    fetchProfileData(); // Fetch the initial profile data when the component mounts

    // Optionally, you can add cleanup logic if needed
    // For example, cancel any ongoing fetch requests or clear the fetched data on unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box mb={['60px',  '60px', '80px',  '80px']} >
        <Box
          pos="fixed"
          top={0}
          right={0}
          left={0}
        
          zIndex={10}
               >
          <Flex alignItems="center" mx={"auto"}  px={[4, 4, 6, 6]}  py={[4, 4, 4, 4]} 
         >
      
              <Box as="button" h={"full"} onClick={handleOpenSideMenu} mr={4}>
                {" "}
                <Flex as="b" align={"center"}>
                  <Icon mr={1} boxSize={5} as={RiMenuLine} />
                  {t("menu")}
                </Flex>
                
                 <SideMenu
                  isOpen={isSideMenuOpen}
                  onClose={handleCloseSideMenu}
                />  
               
              
              </Box>
       

           
           
   
           

            <Spacer />
            <Box>
            
            </Box>
            <Box ml={4}>
              <AuthButton />
            </Box>
            <Hide below="sm">
      
              <Box
                as="button"
                h={"full"}
                align="center"
                onClick={openModal}
                ml={4}
              >
                 <Cart modalOpen={modalOpen} closeModal={closeModal} /> 
               
                <Flex align="center" justify={"center"}>
                  <Icon boxSize={5} mr={1} as={RiShoppingCartLine} />{" "}
                  <Text as="b">
                    {calculateTotal()} {process.env.REACT_APP_CURENCY_SYMBOL}
                  </Text>
                </Flex>
              </Box>
            </Hide>
          </Flex>
        </Box>
      </Box>
      <Hide above="sm">
        {totalQuantity > 0 && (
          <Flex
            flexDir={"column"}
            pos="fixed"
            bottom={0}
            w="full"
            px={6}
            py={4}
            zIndex={10}
            
          >
            <Button size="lg" borderRadius={'xl'} onClick={openModal} justifyContent={'space-between'} shadow={'2xl'}>
              <Flex>
                <Icon boxSize={5} mr={1} as={RiShoppingCartLine} />
                <Text as="b">
                Мой заказ
                </Text>
                </Flex>
                <Text as="b">
                  {calculateTotal()} {process.env.REACT_APP_CURENCY_SYMBOL}
                </Text>
             
            <Cart modalOpen={modalOpen} closeModal={closeModal} />
            
            </Button>
          </Flex>
        )}{" "}
      </Hide>{" "}
    </>
  );
};

export default Navigation;
