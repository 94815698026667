import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CartContext } from "./CartContext";
import ProductModal from "./ProductModal";
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  useToast,
  ButtonGroup,
  Card,
  CardBody,
  Stack,
  CardFooter,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_MAIN_API_URL + "api:aYQXf2CE/nomenclature";
const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const Groups = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { uuid } = useParams();
  const [group, setGroup] = useState(null);
  const { addToCart, cart, incrementQuantity, decrementQuantity } =
    useContext(CartContext);
  const [selectedProductUUID, setSelectedProductUUID] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (uuid) {
      axios
        .get(`${API_URL}/groups/${uuid}`, {
          params: { tenants_id: TENANT_ID },
          headers: { "X-Requested-With": "XMLHttpRequest" },
        })
        .then((res) => setGroup(res.data))
        .catch((error) => {
          console.error("Invalid UUID:", error);
        });
    }
  }, [uuid]);

  if (!group) return null;

  const handleAddToCart = (event, product) => {
    event.stopPropagation();
    addToCart(product);
    toast({
      render: ({ onClose }) => (
        <Box color="white" p={3} borderRadius="lg" bg="rgba(0,0,0,0.5)">
          <Text>
            + {product.name}, {Math.round(product.weight * 1000) + "g"}
          </Text>
        </Box>
      ),
    });
  };

  const handleProductClick = (product) => {
    setSelectedProductUUID(product.uuid);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedProductUUID(null);
    setIsModalOpen(false);
  };

  const handleIncrementQuantity = (event, product) => {
    event.stopPropagation();
    incrementQuantity(product);
    toast({
      render: ({ onClose }) => (
        <Box color="white" p={3} borderRadius="xl" bg="rgba(0,0,0,0.5)">
          <Text>
            + {product.name}, {Math.round(product.weight * 1000) + "g"}
          </Text>
        </Box>
      ),
    });
  };

  const handleDecrementQuantity = (event, product) => {
    event.stopPropagation();
    decrementQuantity(product);
    toast({
      render: ({ onClose }) => (
        <Box color="white" p={3} borderRadius="lg" bg="rgba(0,0,0,0.3)">
          <Text>
            - {product.name}, {Math.round(product.weight * 1000) + "g"}
          </Text>
        </Box>
      ),
    });
  };

  const handleCardClick = (event, product) => {
    // Ignore the click event if it originated from the buttons
    if (event.target.tagName !== "BUTTON") {
      handleProductClick(product);
    }
  };

  return (
    <>
      <SimpleGrid columns={[1, 2, 3, 5]} spacing={4}>
        {group.products.map((product) => {
          const cartItem = cart.find((item) => item.uuid === product.uuid);
          const quantity = cartItem ? cartItem.quantity : 0;
          const pricePerItem =
            product.sizePrices && product.sizePrices.length > 0
              ? product.sizePrices[0].price.currentPrice
              : 0;

          return (
            <Card
              key={product.uuid}
              onClick={(event) => handleCardClick(event, product)}
              _hover={{ cursor: "pointer" }}
              variant = "unstyled"
            >
              <Image
                src={`https://5c17f1d920af4a63f00e.ucr.io/-/resize/x640/-/crop/8:7/center/-/quality/smart_retina/-/enhance/${product.imageLinks[0]}`}
                alt={product.name}
                borderRadius="3xl"
                objectFit="cover"
                objectPosition="center"
                aspectRatio={8 / 7}
              />
              <CardBody px="4" pt="4">
                <Stack spacing={2}>
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Heading size="md">{product.name}</Heading>
                  {quantity < 1 ? (
                     <ButtonGroup isAttached size={'sm'}>
                  <Button onClick={(event) => handleAddToCart(event, product)}   borderRadius={'xl'}>
                    <Text  fontSize="lg">
                  {pricePerItem}
                  {process.env.REACT_APP_CURENCY_SYMBOL}
                </Text>
                  </Button>
                  <Button
                      onClick={(event) =>
                        handleIncrementQuantity(event, product)
                      }
                      borderRadius={'xl'}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup isAttached size={'sm'}>
                    <Button
                      onClick={(event) =>
                        handleDecrementQuantity(event, product)
                      }
                      borderRadius={'xl'}
                    >
                      -
                    </Button>
                    <Button isDisabled>{quantity}</Button>
                    <Button
                      onClick={(event) =>
                        handleIncrementQuantity(event, product)
                      }
                      borderRadius={'xl'}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                )}
                </Flex>
                  <Text fontSize={"xs"}>{product.description}</Text>
                </Stack>
              </CardBody>
              <CardFooter px="4"
              justifyContent={'right'}
              
              >
            
               
              </CardFooter>
            </Card>
          );
        })}
      </SimpleGrid>

      {selectedProductUUID && (
        <ProductModal
          productUUID={selectedProductUUID}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </>
  );
};

export { Groups };
