import React from "react";
import { Box, Flex, Spacer, Hide, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GoBackButton from "./GoBackButton";
import { useLocation } from "react-router-dom";

const CheckoutNav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const cartTitle = t("cart");
  const checkoutTitle = t("checkout");
  const orderReceivedTitle = t("orderRecieved");

  const steps = [
    { title: cartTitle },
    { title: checkoutTitle },
    { title: orderReceivedTitle },
  ];

  const getIndex = () => {
    if (location.pathname === "/checkout") {
      return 1;
    } else if (location.pathname === "/order") {
      return 2;
    }
    return 0;
  };

  return (
    <Box top={0} right={0} left={0} zIndex={10}>
      <Flex alignItems="center" h={[12, 12, 20]} mx="auto" px={[4, 4, 6, 6]}>
        <Box mr={4}>
          <GoBackButton />
        </Box>
        <Spacer />
        <Hide below="sm">
          <Text gap="8">
            {steps.map((step, index) => (
              <Box key={index}>
                {index === getIndex() && <b>{step.title}</b>}
                {index !== getIndex() && ''}
              </Box>
            ))}
          </Text>
        </Hide>
      </Flex>
    </Box>
  );
};

export default CheckoutNav;