// PaymentButton.js
import React from 'react';
import { Button } from "@chakra-ui/react";

const PaymentButton = ({ paymentMethod, orderType, handleSubmit, loading }) => {
  const isApplicable = paymentMethod !== "" && orderType !== "";

  if (!isApplicable) return (
    <Button
      w="100%"
      size="lg"
      isLoading={loading}
      isDisabled
    >
      Выберите способ оплаты
    </Button>
  );;

  return (
    <Button
      w="100%"
      size="lg"
      paymentMethod={paymentMethod}
      colorScheme={paymentMethod === process.env.REACT_APP_PAYMENT_TYPE_SBP ? "purple" : undefined}
      onClick={handleSubmit}
      isLoading={loading}
    >
      Отправить заказ
    </Button>
  );
};

export default PaymentButton;

